.slider-area-wrapper {
  position: relative;
  max-height: 500px;
}

.slider-area-wrapper img {
  width: 100% !important;
}

.slider-area-wrapper .content {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 150px;
}

.slider-area-wrapper p {
  color: #fff;
}

.slider-area-wrapper .content h1 {
  color: white;
  text-transform: uppercase;
  text-align: center;
}

.slider-area-wrapper .content a {
  width: 100%;
  display: block;
  text-transform: uppercase;
  text-align: center;
  background-color: rgb(253, 198, 87);
  border: #0b0b0b;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
  color: black;
}

.content ul li {
  margin-left: 20px;
  list-style-type: "- ";
}

.content ul {
  margin-bottom: 20px;
}

.content table tr td,
.content table tr th {
  padding: 10px;
}

.loading {
  text-align: center;
  width: 100%;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

@media screen and (max-width: 1023px) {
  .slider-area-wrapper .content {
    top: 40px;
  }
}

@media screen and (max-width: 767px) {
  .slider-area-wrapper .content {
    top: 60px;
  }
}

@media screen and (max-width: 544px) {
  .slider-area-wrapper .content {
    top: 70px;
  }

  .slider-area-wrapper .content h1 {
    font-size: 12px;
  }
  .slider-area-wrapper .content P {
    font-size: 10px;
  }
}
